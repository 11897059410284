import _ from 'lodash';
import { Loader } from 'semantic-ui-react';
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { getDatabase, ref, onValue } from 'firebase/database';

// import Login from './views/Login';
import LoginOpen from './templates/Login-Open/Login-Open';
import Dashboard from './views/Dashboard';

import routes from './routes';
import { check, sekolahMateri } from './fetcher';
import { UserContext } from './context';
import DAFTAR_MENU from './daftar_menu.js';

import moment from 'moment';
import 'moment/locale/id';
import {
	LIST_PROGRAM,
	PLATFORM_STATUS_MAINTENANCE,
	PLATFORM_STATUS_LIVE,
} from './helpers/constant';
import firebaseApp from './firebaseApp';
import Maintenance from './collections/Error/Maintenance';

const buildMenu = (list_menu, list_role) => {
	const acl = _.flatMap(list_role, (o) => o.acl).filter(
		(o) => !_.isEmpty(o.level),
	);

	let allowed = _.intersectionBy(list_menu, acl, 'id');
	let list_parent = _.chain(allowed)
		.map((o) => o.parent || o.id)
		.uniq()
		.compact()
		.value();
	list_parent = list_menu.filter((o) => _.includes(list_parent, o.id));
	allowed = _.groupBy(allowed, 'parent');

	const retval = _.chain(list_parent)
		.map((o) => {
			const options = _.get(allowed, o.id, []).map((o) =>
				_.omit(
					{
						key: o.id,
						text: o.menu,
						value: o.to,
						icon: o.icon,
					},
					_.isNil,
				),
			);
			const item = _.assign(
				{
					nama: o.menu,
					type: o.type || 'button',
					value: o.to,
				},
				{ options },
			);
			return item;
		})
		.value();

	return retval;
};
const buildRoute = (list_route, model) =>
	_.chain(list_route)
		.filter((o) => _.includes(o.list_model, model))
		.value();

const DEFAULT_TITLE = 'Impuls Computer Based Test';

const App = () => {
	const [isLoading, setLoading] = useState(true);
	const [session, setSession] = useState({});
	const [platform, setPlatform] = useState({});

	const checkUser = async () => {
		const resp = await check();
		const session = _.get(resp, 'result', {});

		const { roles } = session;

		const list_acl = _.flatMap(roles, (o) => o.acl).filter(
			(o) => !_.isEmpty(o.level),
		);
		const map_acl = _.chain(list_acl)
			.groupBy('id')
			.mapValues((arr) =>
				_.assign({}, _.last(arr), {
					level: _.chain(arr)
						.reduce((sum, o) => sum + _.get(o, 'level', ''), '')
						.split('')
						.uniq()
						.compact()
						.value()
						.join(''),
				}),
			)
			.value();
		_.set(session, 'list_acl', list_acl);
		_.set(session, 'map_acl', map_acl);

		if (session.is_login) {
			const list_name_roles = roles.map((o) => o.nama);
			if (_.includes(list_name_roles, 'sistem')) {
				const fetch = await sekolahMateri.showPenggunaan(
					session.token,
					session.tahun_ajaran_id,
				);
				const fetch_result = _.chain(fetch)
					.get('result.list_bulan', [])
					.find({ tanggal_pretty: moment().format('MMMM YYYY') })
					.value();

				const total_lembar_jawab = _.get(fetch_result, 'total_lembar_jawab');
				const total_materi = _.get(fetch_result, 'total_materi');
				const total_murid_aktif = _.get(fetch_result, 'total_murid_aktif');
				console.log(fetch_result);
				_.set(session, 'total_lembar_jawab', total_lembar_jawab);
				_.set(session, 'total_materi', total_materi);
				_.set(session, 'total_murid_aktif', total_murid_aktif);
			}

			const list_menu = buildMenu(DAFTAR_MENU, session.roles);
			const list_route = buildRoute(routes, session.model);
			const list_program = _.get(session, 'header.list_program', []);
			// console.log("all_menu", DAFTAR_MENU);
			// console.log("list_menu", list_menu);
			// console.log("list_route", list_route);
			_.set(session, 'list_route', list_route);
			_.set(session, 'list_menu', list_menu);
			_.set(
				session,
				'header.list_program',
				_.isEmpty(list_program)
					? LIST_PROGRAM.filter((o) =>
							_.includes(o.jenjang, _.get(session, 'sekolah.jenjang', '')),
						)
					: list_program,
			);
			_.set(
				session,
				'status.tagihan',
				_.chain(session).get('status.tagihan').toFinite().value(),
			);
		}
		setSession((old) => ({ ...old, ...session }));
	};

	useEffect(() => {
		const platformStatus = _.get(platform, 'status');
		if (_.isEqual(platformStatus, PLATFORM_STATUS_LIVE)) {
			checkUser().then(() => {
				setLoading(false);
			});
		}
	}, [platform]);

	useEffect(() => {
		const db = getDatabase(firebaseApp);

		let data = {};
		const starCountRef = ref(db, 'platform');
		onValue(starCountRef, (snapshot) => {
			data = snapshot.val();
			setPlatform(data);
			setSession((old) => ({ ...old, platform: data }));
		});
	}, []);

	const platformStatus = _.get(platform, 'status');
	const platformTanggal = _.get(platform, 'date');

	// platform = maintenance
	if (_.isEqual(platformStatus, PLATFORM_STATUS_MAINTENANCE)) {
		document.title = DEFAULT_TITLE;
		return (
			<div>
				<Maintenance tanggal={platformTanggal} />
			</div>
		);
	} else if (_.isEqual(platformStatus, PLATFORM_STATUS_LIVE)) {
		if (isLoading) {
			return <Loader active />;
		}
		return (
			<UserContext.Provider value={_.assign({ checkUser }, session)}>
				<BrowserRouter>
					<Switch>
						<Route
							path="/login"
							component={LoginOpen}
						/>
						<Route
							path="/"
							component={Dashboard}
						/>
					</Switch>
				</BrowserRouter>
			</UserContext.Provider>
		);
	} else {
		return <div />;
	}
};

export default App;
